import React from 'react'
import Slider from '@fil1pe/react-slider'
import * as st from '~/assets/styl/SliderIntro.module.styl'
import { Link } from 'gatsby'

const SliderIntro = ({ data }) => {
  return (
    <div className={st.core}>
      <Slider slidesToShow={1} slidesToScroll={1} className="slider intro">
        {data.map(
          ({ image, mobile, title, subtitle, cta, url, external }, index) => (
            <div key={index} className="slide">
              <img src={image} className={st.desk} alt="" />
              <div>
                <img src={mobile} className={st.mobile} alt="" />
              </div>
              <div className={st.slideContainer}>
                <section className={st.info}>
                  <h2>{title}</h2>
                  <p>{subtitle}</p>
                  {external === false ? (
                    <Link to={url}>{cta}</Link>
                  ) : (
                    <a href={url} target="_blank" rel="noreferrer">
                      {cta}
                    </a>
                  )}
                </section>
              </div>
            </div>
          )
        )}
      </Slider>
    </div>
  )
}

export default SliderIntro
