import React, { ChangeEvent, useState } from 'react'
import * as st from '~/assets/styl/Benefits.module.styl'
import { AnchorLink } from 'gatsby-plugin-anchor-links'
import InputMask from 'react-input-mask'
import cn from 'classnames'
import requestData from '~/api/form'
import { useCookies } from 'react-cookie'
import { navigate } from 'gatsby'

type Props = {
  data?: any
  location: string
}

const Benefits = ({ data, location }: Props) => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    whatsapp: '',
    safe: 'none',
  })
  const [terms, setTerms] = useState(false)
  const [cookies] = useCookies()

  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const updateForm = { ...formData }
    updateForm[e.target.name] = e.target.value

    setFormData(updateForm)
  }

  const handleSubmit = (e) => {
    e.preventDefault()

    requestData('post', 'conversions', {
      nome: formData.name,
      email: formData.email,
      celular: formData.whatsapp.replace(/\D/g, ''),
      seguro: `Seguro Automóvel - ${formData.safe}`,
      terms_of_use: terms,
      token_rdstation: '4670dfd37e991ea03d5ff714ede2d785',
      identificador: 'simulacao-seguro-automovel',
      c_utmz: cookies?.['__trf.src'] || cookies?.__utmzz,
    }).then((data) => {
      if (data.result === 'success') {
        window.dataLayer = window.dataLayer || []
        window.dataLayer.push({
          event: 'GA - Eventos',
          eventCategory: 'Envio de formulário',
          eventLabel: 'Formulário de contato',
        })
        navigate('/sucesso')
      } else {
        alert(data.msg)
      }
    })
  }

  return (
    <div
      className={
        location === 'seguro-automovel' ? cn(st.paddingNone, st.core) : st.core
      }
    >
      <section className="container clear-fix">
        <h2 className="grid-9">
          {location === '/seguros/'
            ? 'Vantagens de contratar o seguro com a Dimas Seguros'
            : data.advantageTitle}
        </h2>
        {location === '/seguros/' ? (
          <ul className="grid-25 offset-2">
            <li>
              <h3>Proteção do patrimônio</h3>
              <p>
                Seguros contra roubos, acidentes ou pequenos danos cotidianos,
                com serviços variados que atendem sua necessidade.
              </p>
            </li>
            <li>
              <h3>Cobertura e benefícios</h3>
              <p>
                Tudo adaptável à sua realidade. Reduzir a franquia? Ter um carro
                reserva? Contar com serviço de carona? Você escolhe, a gente
                proporciona.
              </p>
            </li>
            <li>
              <h3>
                Assistência <span className="block">24 horas</span>
              </h3>
              <p>
                A qualquer hora: é só entrar em contato que resolveremos o seu
                imprevisto.
              </p>
            </li>
          </ul>
        ) : (
          <ul className="grid-25 offset-2">
            {data?.advantageContent.map(({ name, description }, index) => (
              <li key={index}>
                <h3>{name}</h3>
                <p>{description}</p>
              </li>
            ))}
          </ul>
        )}
      </section>
      {location === 'seguro-automovel' && (
        <section className={st.formContainer} id="simulacao">
          <div className={cn(st.wrapper, 'container')}>
            <h2 className={st.formTitle}>Solicite uma simulação</h2>
            <form onSubmit={handleSubmit} className={st.form}>
              <label htmlFor="name" className={st.label}>
                <span>Nome</span>
                <input
                  type="text"
                  id="name"
                  placeholder="Insira seu nome completo"
                  required
                  name="name"
                  onChange={handleChange}
                  value={formData.name}
                />
              </label>
              <label htmlFor="email" className={st.label}>
                <span>E-mail</span>
                <input
                  type="email"
                  placeholder="Insira seu melhor e-mail"
                  required
                  name="email"
                  onChange={handleChange}
                  id="email"
                  value={formData.email}
                />
              </label>
              <label htmlFor="whatsapp" className={st.label}>
                <span>WhatsApp</span>
                <InputMask
                  type="text"
                  id="whatsapp"
                  name="whatsapp"
                  placeholder="Insira seu DDD + número de WhatsApp"
                  mask="(99) 99999-9999"
                  required
                  onChange={handleChange}
                  value={formData.whatsapp}
                />
              </label>
              <label htmlFor="safe" className={st.label}>
                <span>Seguro</span>
                <select
                  name="safe"
                  id="safe"
                  required
                  onChange={handleChange}
                  value={formData.safe}
                >
                  <option value="none" disabled defaultValue={'Selecione'}>
                    Selecione
                  </option>
                  <option value="Novo seguro">Novo seguro</option>
                  <option value="Renovação">Renovação de seguro</option>
                </select>
              </label>
              <div className={st.formBotton}>
                <div className={st.checkbox}>
                  <input
                    type="checkbox"
                    value="terms"
                    name="terms"
                    id="terms"
                    required
                    checked={terms}
                    onChange={(e) => setTerms(e.target.checked)}
                  />
                  <label htmlFor="terms">
                    Li e concordo com os{' '}
                    <a href="/termos-de-uso/">termos de uso.</a>
                  </label>
                </div>
                <button className={st.submitButton}>Enviar</button>
              </div>
            </form>
          </div>
        </section>
      )}
      {location === '/seguros/' || location === 'seguro-automovel' ? (
        ''
      ) : (
        <AnchorLink to={`/${location}/#fale-conosco`}>
          Solicite informações
        </AnchorLink>
      )}
    </div>
  )
}

export default Benefits
