// extracted by mini-css-extract-plugin
export var checkbox = "Benefits-module--checkbox--Qpm+Q";
export var core = "Benefits-module--core--CV8ik";
export var form = "Benefits-module--form--6K6Si";
export var formBotton = "Benefits-module--formBotton--W+8My";
export var formContainer = "Benefits-module--form-container--MTfDA";
export var formTitle = "Benefits-module--form-title--Eh616";
export var label = "Benefits-module--label--UYMfH";
export var paddingNone = "Benefits-module--padding-none--7grDU";
export var submitButton = "Benefits-module--submitButton--eupT1";
export var wrapper = "Benefits-module--wrapper--IwZxj";