import React from 'react'
import * as st from '~/assets/styl/Loading.module.styl'

interface Props {
  active: boolean
}

const Loading = ({ active }: Props) => {
  return (
    <div className={st.core} style={!active ? { opacity: 0 } : null}>
      <div className={st.spinner}>
        <svg viewBox="22 22 44 44">
          <circle cx="44" cy="44" r="20.2" fill="none" strokeWidth="3.6" />
        </svg>
      </div>
    </div>
  )
}

export default Loading
