import React from 'react'
import { Link, graphql, useStaticQuery } from 'gatsby'
import { useApi } from '~/api'
import * as st from '~/assets/styl/Footer.module.styl'
import logo from '~/assets/svg/dimas-seguro-footer.png'
import cn from 'classnames'

type Props = {
  hasNewsletter?: boolean
  inDesk?: boolean
}

const Footer = ({ hasNewsletter, inDesk }: Props) => {
  const query = useStaticQuery(graphql`
    query {
      allSocials {
        nodes {
          facebook
          instagram
        }
      }
      allWhatsApps {
        nodes {
          number
          name
        }
      }
      allSafes {
        nodes {
          name
          slug
        }
      }
    }
  `)

  const socialsData = useApi(
    {
      social: query.allSocials.nodes,
      whatsapps: query.allWhatsApps.nodes,
      safes: query.allSafes.nodes,
    },
    'configuration'
  )

  const handleWhatsAppClick = (name) => {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      event: 'GA - Eventos',
      eventCategory: 'Clique de rodapé',
      eventLabel: 'Número do WhatsApp - ' + name,
      eventAction: 'Clique',
    })
  }

  return (
    <footer
      className={cn(
        st.core,
        hasNewsletter && st.customPadding,
        inDesk && st.noPadding
      )}
    >
      <div className="container clear-fix">
        <div>
          <Link to="/">
            <img
              src={logo}
              alt="Logo da D e D Corretora de Seguros"
              loading="lazy"
            />
          </Link>
          <p>
            A Dimas Seguros atua em estrita observância à legislação securitária
            com registro de SUSEP n. 1020338589.
          </p>
          <div>
            <small>© 2023 Dimas Seguros Corretora de Seguros.</small>
            <small>Todos os direitos reservados.</small>
          </div>
        </div>
        <section>
          <h2>
            <Link to="/seguros/">Seguros</Link>
          </h2>
          <ul>
            {socialsData.safes.map(({ slug, name }, index) => (
              <li key={index}>
                <Link to={`/${slug}/`}>{name}</Link>
              </li>
            ))}
          </ul>
        </section>
        <section>
          <h2>
            <Link to="/sobre/">Institucional</Link>
          </h2>
          <ul>
            <li>
              <Link to="/sobre/">Sobre a Dimas Seguros</Link>
            </li>
            <li>
              <Link to="/contato/">Fale Conosco</Link>
            </li>
            <li>
              <Link
                target="__blank"
                to="http://www.dimas.com.br/trabalhe-conosco"
              >
                Trabalhe Conosco
              </Link>
            </li>
            <li>
              <Link to="/politica-de-privacidade/">
                Política de Privacidade
              </Link>
            </li>
            <li>
              <Link to="/termos-de-uso/">Termos de Uso</Link>
            </li>
          </ul>
        </section>
        <section>
          <h2>Contate-nos pelo WhatsApp:</h2>
          <ul>
            {socialsData.whatsapps?.map(({ name, number }, index) => (
              <li key={index}>
                <a
                  href={`https://api.whatsapp.com/send?phone=55${number.replace(
                    /[^0-9]/g,
                    ''
                  )}&text=Ol%C3%A1%21%20Visitei%20o%20site%20da%20Dimas%20Seguros%20e%20tenho%20interesse%20em%20saber%20mais.`}
                  target="_blank"
                  rel="noreferrer"
                  onClick={() => handleWhatsAppClick(name)}
                >
                  {number}
                </a>
              </li>
            ))}
          </ul>
          <ul>
            <li>
              <a
                href={socialsData.social?.facebook}
                target="_blank"
                rel="noreferrer"
              >
                Facebook
              </a>
            </li>
            <li>
              <a
                href={socialsData.social?.instagram}
                target="_blank"
                rel="noreferrer"
              >
                Instagram
              </a>
            </li>
          </ul>
        </section>
      </div>
      <a href="https://www.tiki.com.br/" target="_blank" rel="noreferrer">
        Tiki
      </a>
    </footer>
  )
}

export default Footer
