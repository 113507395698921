import React from 'react'
import { Link } from 'gatsby'
import { AnchorLink } from 'gatsby-plugin-anchor-links'
import * as st from '~/assets/styl/Insurances.module.styl'
import cn from 'classnames'

type Props = {
  hasClipPath?: boolean
  location: Location
  title?: string
  text?: string
  data: Array<any>
}

const Insurances = ({ hasClipPath, location, title, text, data }: Props) => {
  const belowTwo = data.filter((card, index) => card && index < 2)
  const overTwo = data.filter((card, index) => card && index >= 2)

  return (
    <div className={cn(st.core, hasClipPath && st.clipPath)}>
      <div className="container clear-fix">
        <section className="grid-18">
          {location.pathname === '/' ? (
            <h1>
              Sua vida{' '}
              <span className="block">mais tranquila, do seu jeito</span>
            </h1>
          ) : (
            <h2>{title}</h2>
          )}
          <p>{text}</p>
        </section>
        <div className="grid-18 content-desk">
          {belowTwo.map(({ resume, name, slug }, index) => (
            <section className={st.cardsContainer} key={index}>
              <h2>{name}</h2>
              <ul className={st.list}>
                {resume.map((item, index) => (
                  <li key={index}>{item.replace('/', '/ ')}</li>
                ))}
              </ul>
              {slug === 'seguro-automovel' ? (
                <AnchorLink to="/seguro-automovel/#simulacao">
                  Simule
                </AnchorLink>
              ) : (
                <Link to={'/' + slug + '/'}>Ver mais</Link>
              )}
            </section>
          ))}
        </div>
        <div className="grid-36 clear-fix content-desk">
          {overTwo.map(({ resume, name, slug }, index) => (
            <section className={st.cardsContainer} key={index}>
              <h2>{name}</h2>
              <ul className={st.list}>
                {resume.map((item, index) => (
                  <li key={index}>{item.replace('/', '/ ')}</li>
                ))}
              </ul>
              <Link to={'/' + slug + '/'}>Ver mais</Link>
            </section>
          ))}
        </div>
      </div>
      <div>
        <div>
          {data.map(({ resume, name, slug }, index) => (
            <section key={index} className={st.cardsContainer}>
              <h2>{name}</h2>
              <ul className={st.list}>
                {resume.map((item, index) => (
                  <li key={index}>{item.replace('/', '/ ')}</li>
                ))}
              </ul>
              {slug === 'seguro-automovel' ? (
                <AnchorLink to="/seguro-automovel/#simulacao">
                  Simule
                </AnchorLink>
              ) : (
                <Link to={'/' + slug + '/'}>Ver mais</Link>
              )}
            </section>
          ))}
        </div>
      </div>
    </div>
  )
}

export default Insurances
