import React from 'react'
import * as st from '~/assets/styl/TalkUs.module.styl'
import Form from './Form'

const TalkUs = ({ data }) => {
  return (
    <div className={st.core} id="fale-conosco">
      <section className="container clear-fix">
        <h2>{data.formTitle}</h2>
        <p>{data.formContent}</p>
        <Form textBtn="Solicitar informações" id={data.name} />
      </section>
    </div>
  )
}

export default TalkUs
