// Aqui os dados do head das páginas

import React, { useEffect, useState } from 'react'
import Helmet from 'react-helmet'

interface Props {
  title?: string
  description?: string
  location?: Location
}

const DEFAULT_TITLE = process.env.GATSBY_SITE_NAME
const DEFAULT_DESCRIPTION =
  'Conheça os seguros que a Dimas Seguros oferece: seguro automóvel, seguro residencial, seguro de vida, seguro viagens, seguro previdência e mais!'

const isClientSide = typeof window !== 'undefined'

const Head = ({ title, description, location }: Props) => {
  const [shouldRenderDescription, setShouldRenderDescription] = useState(
    !isClientSide
  )
  useEffect(() => {
    if (shouldRenderDescription) return
    document.querySelectorAll('meta[name="description"]').forEach((el) => {
      el.remove()
    })
    setShouldRenderDescription(true)
  }, [])

  return (
    <Helmet>
      <meta
        property="og:image"
        itemProp="image"
        content={`https://${process.env.GATSBY_SITE_URL}/open-graph.png`}
      />
      <meta
        name="twitter:image"
        content={`https://${process.env.GATSBY_SITE_URL}/open-graph.png`}
      />
      <title>{title || DEFAULT_TITLE}</title>
      <meta property="og:title" content={title || DEFAULT_TITLE} />
      <meta property="og:site_name" content={process.env.GATSBY_SITE_NAME} />
      {shouldRenderDescription && (
        <meta name="description" content={description || DEFAULT_DESCRIPTION} />
      )}
      <meta
        property="og:description"
        content={description || DEFAULT_DESCRIPTION}
      />
      <link
        rel="canonical"
        href={`https://${process.env.GATSBY_SITE_URL}${
          location?.pathname || ''
        }`}
      />
      <meta
        property="og:url"
        content={`https://${process.env.GATSBY_SITE_URL}${
          location?.pathname || ''
        }`}
      />
      <link rel="shortcut icon" href="/favicon.ico?v=1" type="image/x-icon" />
      <meta property="og:locale" content="pt_BR" />
      <meta charSet="utf-8" />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, shrink-to-fit=no"
      />
      <meta
        name="adopt-website-id"
        content="1e25b412-f06a-424a-9258-e1602c730bc9"
      />
      <script
        src="//tag.goadopt.io/injector.js?website_code=1e25b412-f06a-424a-9258-e1602c730bc9"
        className="adopt-injector"
      ></script>
      <script src="https://nimble.com.br/snippet.js"></script>
    </Helmet>
  )
}

export default Head
