import React from 'react'
import * as st from '~/assets/styl/Contacts.module.styl'
import { useStaticQuery, graphql } from 'gatsby'
import { useApi } from '~/api'

const Contacts = () => {
  const query = useStaticQuery(graphql`
    query {
      allWhatsApps {
        nodes {
          number
          name
        }
      }
    }
  `)

  const whatsData = useApi(
    { whatsapps: query.allWhatsApps.nodes },
    'configuration'
  )

  const handleWhatsAppClick = (name) => {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      event: 'GA - Eventos',
      eventCategory: 'Clique de botão',
      eventLabel: 'Botão do WhatsApp - ' + name,
      eventAction: 'Clique',
    })
  }

  return whatsData.whatsapps.length > 1 ? (
    <section className={st.core}>
      <h2>Chamar no WhatsApp</h2>
      <ul>
        {whatsData.whatsapps.map(({ name, number }, index) => (
          <li key={index}>
            <a
              href={`https://api.whatsapp.com/send?phone=55${number.replace(
                /[^0-9]/g,
                ''
              )}&text=Ol%C3%A1%21%20Visitei%20o%20site%20da%20Dimas%20Seguros%20e%20tenho%20interesse%20em%20saber%20mais.`}
              target="_blank"
              rel="noreferrer"
              onClick={() => handleWhatsAppClick(name)}
            >
              {name}
            </a>
          </li>
        ))}
      </ul>
    </section>
  ) : (
    <div className={st.core}>
      {whatsData.whatsapps.map(({ name, number }, index) => (
        <a
          href={`https://api.whatsapp.com/send?phone=55${number.replace(
            /[^0-9]/g,
            ''
          )}&text=Ol%C3%A1%21%20Visitei%20o%20site%20da%20Dimas%20Seguros%20e%20tenho%20interesse%20em%20saber%20mais.`}
          target="_blank"
          rel="noreferrer"
          onClick={() => handleWhatsAppClick(name)}
          key={index}
        >
          {name}
        </a>
      ))}
    </div>
  )
}

export default Contacts
