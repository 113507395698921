import React from 'react'
import { Link } from 'gatsby'
import {
  StyledEngineProvider,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import * as st from '~/assets/styl/Questions.module.styl'
import '~/assets/styl/accordion.styl'
import cn from 'classnames'
import nl2br from 'react-nl2br'

const Questions = ({ data }) => {
  return (
    <section className={cn('container', st.core)}>
      <h2>Perguntas Frequentes</h2>
      <div className={st.accordion}>
        <StyledEngineProvider injectFirst>
          {data.map(({ title, description }, index) => (
            <Accordion key={index}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>{nl2br(title)}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>{nl2br(description)}</Typography>
              </AccordionDetails>
            </Accordion>
          ))}
        </StyledEngineProvider>
      </div>
      <div>
        <p>Ainda ficou com dúvidas?</p>
        <Link to="/contato/">Fale conosco</Link>
      </div>
    </section>
  )
}

export default Questions
