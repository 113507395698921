import React from 'react'
import { Link } from 'gatsby'
import * as st from '~/assets/styl/Numbers.module.styl'

interface Props {
  location?: string
  data: any
}

const Numbers = ({ data, location }: Props) => {
  return (
    <div className={st.core}>
      <div className="container clear-fix">
        <section className="grid-6">
          <h2>Principais números</h2>
          {location === '/' && <Link to="/sobre/">Saiba mais</Link>}
        </section>
        <div className="grid-26 offset-4">
          <ul>
            {data.map(({ number, name }, index) => (
              <li key={index}>
                <p>
                  {number}
                  <span> {name}</span>
                </p>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  )
}

export default Numbers
