import React, { useState, useEffect } from 'react'
import Slider from '@fil1pe/react-slider'
import * as st from '~/assets/styl/SliderAd.module.styl'
import { AnchorLink } from 'gatsby-plugin-anchor-links'
import cn from 'classnames'

interface SliderAdProps {
  data: [
    {
      image: string
      mobile: string
      title: string
      url: string
      external: boolean
    }
  ]
  withPadding?: boolean
}

const SliderAd = ({ data, withPadding = false }: SliderAdProps) => {
  const [currentUrl, setCurrentUrl] = useState('')

  useEffect(() => {
    setCurrentUrl(location.pathname)
  })

  return (
    <div
      className={cn(
        currentUrl === '/seguro-automovel/'
          ? cn(st.paddingNone, st.core)
          : st.core,
        withPadding && currentUrl !== '/seguro-automovel/' && st.customPadding
      )}
    >
      <Slider slidesToShow={1} slidesToScroll={1} className="slider ad">
        {data.map(({ image, mobile, title, url, external }, index) =>
          external === false ? (
            <AnchorLink
              to={
                url.split('/').includes(currentUrl.replace(/['/']+/g, '')) &&
                currentUrl !== '/'
                  ? url.split('/')[url.split('/').length - 1]
                  : url
              }
              key={index}
              className="slide"
            >
              <img src={image} className={st.desk} alt={title} />
              <img src={mobile} className={st.mobile} alt={title} />
            </AnchorLink>
          ) : (
            <a
              href={url}
              target="_blank"
              key={index}
              className="slide"
              rel="noreferrer"
            >
              <img src={image} className={st.desk} alt={title} />
              <img src={mobile} className={st.mobile} alt={title} />
            </a>
          )
        )}
      </Slider>
    </div>
  )
}

export default SliderAd
